<template>
  <div class="content-wrap">
    <div class="details-header">
      <div class="header-top">
        <span>组织详情</span>
      </div>
      <div class="header-bottom">
        <el-form ref="organization" :model="organization" label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="是否开票组织：">
                <span>{{ organization.isTaxpayer === 'N' ? '否' : '是' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织编码：">
                <span>{{ organization.uniqueCode }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="上级组织：">
                <span>{{organization.parentName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织名称：">
                <span>{{organization.name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织税号：">
                <span>{{organization.taxNo}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="组织地址：">
                <el-tooltip effect="dark" :content="organization.address" placement="top-start">
                  <span style="max-width: 20em;">{{organization.address}}</span>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织电话：">
                <span>{{organization.phone}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户银行：">
                <el-tooltip effect="dark" :content="organization.bank" placement="top-start">
                  <span style="max-width: 18em;">{{organization.bank}}</span>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="银行账号：">
                <span>{{organization.account}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人：">
                <span>{{ organization.contacts }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系方式：">
                <span>{{ organization.contactsWay }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="数电试点企业：">
                <span>{{ organization.isElec ? '是' : '否' }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="details-main">
      <div class="license">
        <div class="header-top">
          <span>营业执照</span>
        </div>
        <div class="license-body">
          <img class="license-show" v-if="organization.fileUrl!=null&&organization.fileUrl!=''"
               :src="organization.fileUrl">
          <img v-else src="@/assets/icon/upload@2x.png">
        </div>
      </div>
      <div class="qrcode">
        <div class="header-top">
          <span>组织二维码</span>
        </div>
        <div class="qrcode-body">
          <img
            src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1597381114260&di=4a81bf32970255a92b692eda40c733eb&imgtype=0&src=http%3A%2F%2F1832.img.pp.sohu.com.cn%2Fimages%2Fblog%2F2012%2F6%2F22%2F13%2F4%2Fe12682497_138d53eb8c7g215_blog.gif">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {selectOrganization} from '@/service/organization'

export default {
  data () {
    return {
      organization: {}
    }
  },
  created () {
    let id = this.$route.query.id
    this.handleSelectOrganization(id)
  },
  methods: {
    // 获取组织详情
    handleSelectOrganization (id) {
      selectOrganization(id).then(res => {
        if (res.success) {
          this.organization = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .details-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 20px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
    }
  }

  .details-main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .license {
      width: 50%;
      height: 528px;
      background-color: #fff;
      overflow-x: auto;

      .license-body {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-top: 48px;
        margin-left: 202px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }
    }

    .qrcode {
      width: 49%;
      height: 528px;
      background-color: #fff;
      overflow-x: auto;

      .qrcode-body {
        display: flex;
        justify-content: center;

        img {
          width: 240px;
          height: 240px;
          background: rgba(229, 229, 229, 1);
          text-align: center;
          margin: 48px 202px 0px 202px;
        }
      }
    }
  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;
  }

  .header-top span:before {
    content: "|";
    display: inline-block;
    margin-right: 10px;
    background-color: #333;
  }
</style>
